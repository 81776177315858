//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Bar',
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: function _default() {}
    },
    scale: {
      type: Array,
      default: function _default() {
        return [{
          dataKey: 'x',
          min: 2
        }, {
          dataKey: 'y',
          title: '时间',
          min: 1,
          max: 22
        }];
      }
    },
    tooltip: {
      type: Array,
      default: function _default() {
        return ['x*y', function (x, y) {
          return {
            name: x,
            value: y
          };
        }];
      }
    }
  },
  data: function data() {
    return {
      arr: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    setTimeout(function (_) {
      _this.arr = _this.list;
    }, 300);
  }
};