import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils';

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  var si = [{
    value: 1E18,
    symbol: 'E'
  }, {
    value: 1E15,
    symbol: 'P'
  }, {
    value: 1E12,
    symbol: 'T'
  }, {
    value: 1E9,
    symbol: 'G'
  }, {
    value: 1E6,
    symbol: 'M'
  }, {
    value: 1E3,
    symbol: 'k'
  }];
  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 *
 * (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 * (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
 * @returns
 */
export function dateFormat(value) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-dd';
  if (!value) {
    return '- -';
  }
  if (typeof value == 'number' && String(value).length === 10) value = value * 1000;
  var _this = new Date(value);
  var o = {
    'M+': _this.getMonth() + 1,
    //月份
    'd+': _this.getDate(),
    //日
    'h+': _this.getHours(),
    //小时
    'm+': _this.getMinutes(),
    //分
    's+': _this.getSeconds(),
    //秒
    'q+': Math.floor((_this.getMonth() + 3) / 3),
    //季度
    S: _this.getMilliseconds() //毫秒
  };

  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (_this.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o) if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  return fmt;
}

// "大数据显示":0,
var BigNumber = {
  "中文大数字标识": 0,
  "CNKeyWord": ["万", "亿", "万亿", "亿亿", "万亿亿", "亿亿亿", "万亿亿亿", "亿亿亿亿", "万亿亿亿亿", "亿亿亿亿亿", "万亿亿亿亿亿", "亿亿亿亿亿亿"],
  "英文大数字标识": 0,
  "ENKeyWord": ["K", "M", "G", "T", "aa", "bb", "cc", "dd", "ee", "ff", "gg", "hh", "ii", "jj", "kk", "ll", "mm", "nn", "oo", "pp", "qq", "rr", "ss", "tt", "uu", "vv", "ww", "xx", "yy", "zz"]
};
export function numOverLenghtEN(num) {
  num = num + "";
  var reg = /^[0-9]+.?[0-9]+$/; //判断字符串是否为数字
  if (!reg.test(num)) {
    return num;
  }
  var str = null;
  var split = num.split(".");
  var initStr = split[0];
  var dotStr = split[1];
  var len = initStr.length;
  if (len < 4) {
    var pLen = onGetPointLen(len);
    var str_ = dotStr == undefined ? num : parseFloat(num).toFixed(pLen);
    str = Number(str_) + "";
  } else if (len >= 4) {
    var unitIdx = Math.floor((len - 1) / 3) - 1;
    var unit = getNumberENKeys(unitIdx);
    var valueIdx = (len - 1) % 3;
    //	if (valueIdx < 2){
    var value = initStr.substr(0, valueIdx + 3);
    value = (parseInt(value) / 100).toFixed(2);
    var vSplit = String(value).split(".");
    var _pLen = onGetPointLen(vSplit[0].length);
    var value_ = Number(value).toFixed(_pLen);
    value = Number(value_) + "";
    str = value + unit;

    // }else {
    // 	let value = num.substr(0, valueIdx + 1);
    // 	value = parseInt(value) ;
    // 	str = value + unit;
    // }
  }

  return str;
}
function getNumberENKeys(idx) {
  var key = BigNumber.ENKeyWord;
  return idx < key.length ? key[idx] : "ERR";
}
function onGetPointLen(numlen) {
  if (numlen >= 3) {
    return 0;
  }
  if (numlen == 2) {
    return 1;
  }
  return 2;
}