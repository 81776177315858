var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "action-ul" },
    [
      _vm._l(_vm.btns, function (btn, index) {
        return [
          btn === "add"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permisaction",
                      rawName: "v-permisaction",
                      value: _vm.addPermisaction,
                      expression: "addPermisaction",
                    },
                  ],
                  key: index,
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增")]
              )
            : _vm._e(),
          btn === "update"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permisaction",
                      rawName: "v-permisaction",
                      value: _vm.updatePermisaction,
                      expression: "updatePermisaction",
                    },
                  ],
                  key: index,
                  attrs: {
                    type: "success",
                    icon: "el-icon-edit",
                    size: "mini",
                  },
                  on: { click: _vm.handleUpdate },
                },
                [_vm._v("修改")]
              )
            : _vm._e(),
          btn === "delete"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permisaction",
                      rawName: "v-permisaction",
                      value: _vm.deletePermisaction,
                      expression: "deletePermisaction",
                    },
                  ],
                  key: index,
                  attrs: {
                    type: "danger",
                    icon: "el-icon-delete",
                    size: "mini",
                  },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          btn === "export"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permisaction",
                      rawName: "v-permisaction",
                      value: _vm.exportPermisaction,
                      expression: "exportPermisaction",
                    },
                  ],
                  key: index,
                  attrs: {
                    type: "warning",
                    icon: "el-icon-download",
                    size: "mini",
                  },
                  on: { click: _vm.handleExport },
                },
                [_vm._v("导出")]
              )
            : _vm._e(),
        ]
      }),
      _vm._t("append"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }