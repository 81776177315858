import _typeof from "G:/renzhe/admin_vue/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
var storage = {
  set: function set(key, value) {
    if (_typeof(value) === 'object') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  },
  get: function get(key) {
    var value = localStorage.getItem(key);
    if (value && value.indexOf('{') !== -1) {
      value = JSON.parse(value);
    }
    return value;
  },
  remove: function remove(key) {
    localStorage.removeItem(key);
  },
  clear: function clear() {
    localStorage.clear();
  }
};
export default storage;