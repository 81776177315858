//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RankList',
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: null
    }
  }
};