var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { padding: "0 0 32px 32px" } },
    [
      _c("h4", { style: { marginBottom: "20px" } }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c(
        "v-chart",
        {
          attrs: {
            height: "254",
            data: _vm.arr,
            "force-fit": true,
            padding: ["auto", "auto", "40", "50"],
          },
        },
        [
          _c("v-tooltip"),
          _c("v-axis"),
          _c("v-bar", { attrs: { position: "x*y" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }