import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartCard from '@/components/ChartCard';
import Trend from '@/components/Trend';
import MiniArea from '@/components/MiniArea';
import MiniBar from '@/components/MiniBar';
import MiniProgress from '@/components/MiniProgress';
import RankList from '@/components/RankList/index';
import Bar from '@/components/Bar.vue';
var barData = [];
var barData2 = [];
for (var i = 0; i < 12; i += 1) {
  barData.push({
    x: "".concat(i + 1, "\u6708"),
    y: Math.floor(Math.random() * 1000) + 200
  });
  barData2.push({
    x: "".concat(i + 1, "\u6708"),
    y: Math.floor(Math.random() * 1000) + 200
  });
}
var rankList = [];
for (var _i = 0; _i < 7; _i++) {
  rankList.push({
    name: '白鹭岛 ' + (_i + 1) + ' 号店',
    total: 1234.56 - _i * 100
  });
}
export default {
  name: 'DashboardAdmin',
  components: {
    ChartCard: ChartCard,
    Trend: Trend,
    MiniArea: MiniArea,
    MiniBar: MiniBar,
    MiniProgress: MiniProgress,
    RankList: RankList,
    Bar: Bar
  },
  data: function data() {
    return {
      barData: barData,
      barData2: barData2,
      rankList: rankList
    };
  },
  methods: {}
};