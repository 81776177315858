import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SearchBar',
  props: {
    searchItems: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    btns: {
      type: Array,
      default: function _default() {
        return ['search', 'reset'];
      }
    },
    searchPermisaction: {
      type: Array,
      default: function _default() {
        return ['admin'];
      }
    },
    exportPermisaction: {
      type: Array,
      default: function _default() {
        return ['admin'];
      }
    },
    dtype: {
      type: String,
      default: function _default() {
        return 'daterange';
      }
    }
  },
  data: function data() {
    return {
      searchData: {},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  mounted: function mounted() {
    this.update();
  },
  methods: {
    update: function update() {
      var searchData = {};
      this.searchItems.forEach(function (s) {
        searchData[s.prop] = typeof s.value === 'undefined' ? '' : s.value;
      });
      this.searchData = searchData;
    },
    doSearch: function doSearch() {
      this.setTime();
      this.$emit('search', this.searchData);
    },
    setTime: function setTime() {
      if (this.searchData.hasOwnProperty('date')) {
        if (this.searchData.date && this.searchData.date.length > 0) {
          this.searchData.beginTime = this.searchData.date[0];
          this.searchData.endTime = this.searchData.date[1];
          // this.searchData.endTime = this.searchData.date[1].replace(
          //   '00:00:00',
          //   '23:59:59'
          // )
          // this.searchData.date[1] = this.searchData.endTime
          // this.$set(this.searchData, "date", this.searchData.date)
        } else {
          this.searchData.beginTime = '';
          this.searchData.endTime = '';
        }
      }
    },
    resetForm: function resetForm() {
      for (var key in this.searchData) {
        this.searchData[key] = null;
      }
      this.$emit('search', this.searchData);
      this.$emit('reset');
    },
    itemChange: function itemChange(key) {
      var _this = this;
      console.log("选中改变", key);
      this.setTime();
      this.searchItems.forEach(function (s) {
        if (s.prop === key) {
          console.log("选中改变11111 ", s);
          if (typeof s.change === 'function') {
            console.log("选中改变222 ", s);
            s.change(_this.searchData, key);
          }
        }
      });
    },
    itemInput: function itemInput(key) {
      var _this2 = this;
      this.setTime();
      this.searchItems.forEach(function (s) {
        if (s.prop === key) {
          if (typeof s.inputChange === 'function') {
            s.inputChange(_this2.searchData, key);
          }
        }
      });
    },
    doExport: function doExport() {
      this.$emit('export');
    }
  }
};