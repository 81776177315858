import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CompareForm",
  data: function data() {
    return {
      chartForm: {
        channel: '',
        serverId: ''
      },
      visible: false
    };
  },
  computed: {
    serverData: function serverData() {
      // 服务器列表
      var gameRegions = this.$store.getters.gameRegions;
      var serverOptions = [{
        label: '全服',
        value: ''
      }];
      gameRegions.forEach(function (g) {
        serverOptions.push({
          label: g.name,
          value: g.id
        });
      });
      return serverOptions;
    },
    channelOptions: function channelOptions() {
      var channel = this.$store.getters.channelData;
      var channelOptions = [{
        label: '全渠道',
        value: ''
      }];
      channel.forEach(function (c) {
        channelOptions.push({
          label: c.channelName,
          value: c.channelName
        });
      });
      return channelOptions;
    }
  },
  methods: {
    show: function show() {
      var _this = this;
      this.visible = true;
      setTimeout(function () {
        document.addEventListener('click', _this.close);
      }, 300);
    },
    close: function close() {
      document.removeEventListener('click', this.close);
      this.visible = false;
    },
    stop: function stop() {},
    confirm: function confirm() {
      this.$emit('compare', this.chartForm);
      this.close();
    },
    clear: function clear() {
      this.$emit('clear');
      this.close();
    }
  }
};