import { gmRegionsAll } from '@/api/game/gm-regions';
import { gmGetConfigs } from "@/api/game/gm-configs";
var state = {
  gameRegions: [],
  propCfg: {},
  //物品配置
  currencyCfg: {} //货币配置
};

var mutations = {
  GAME_REGIONS_ALL: function GAME_REGIONS_ALL(state, e) {
    state.gameRegions = e;
  },
  GAME_PROP_CFG: function GAME_PROP_CFG(state, e) {
    state.propCfg = e;
  },
  GAME_CURRENCY_CFG: function GAME_CURRENCY_CFG(state, e) {
    state.currencyCfg = e;
  }
};
var actions = {
  getGameRegions: function getGameRegions(_ref, e) {
    var commit = _ref.commit;
    gmRegionsAll().then(function (res) {
      commit('GAME_REGIONS_ALL', res.data.list);
    });
  },
  getPropCfg: function getPropCfg(_ref2, e) {
    var commit = _ref2.commit;
    gmGetConfigs("PorpCfg").then(function (res) {
      commit('GAME_PROP_CFG', res);
    });
  },
  getCurrenyCfg: function getCurrenyCfg(_ref3, e) {
    var commit = _ref3.commit;
    gmGetConfigs("CurrencyCfg").then(function (res) {
      commit('GAME_CURRENCY_CFG', res);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};