import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.to-string.js";
// 查询列表
export function getItems(f, query) {
  query = query || {
    pageSize: 10000
  };
  return f(query);
}
export function setItems(response, k, v) {
  var data = [];
  k = k || 'id';
  v = v || 'name';
  if (response.data && response.data.list && response.data.list.length > 0) {
    response.data.list.forEach(function (e) {
      data.push({
        key: e[k].toString(),
        value: e[v].toString()
      });
    });
    return data;
  }
}