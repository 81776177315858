import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
/**
 * 数字千分位逗号分割格式化显示
 * @param {string} num
 * @returns {string}
 */
export function ThousandsFormat(num) {
  num = num + "";
  // 判断字符串是否为数字
  var reg = /^[0-9]+.?[0-9]+$/;
  if (!reg.test(num)) {
    return num;
  }
  var str = num.toString();
  var pattern = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
  return str.replace(pattern, "$1,");
}
export function ThousandsAndFixedFormat(num) {
  return num.toLocaleString();
}
export function PercentFormat(num) {
  return num.toLocaleString('zh', {
    style: 'percent'
  });
}