import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCodeImg } from '@/api/login';
import moment from 'moment';
import SocialSign from './components/SocialSignin';
export default {
  name: 'Login',
  components: {
    SocialSign: SocialSign
  },
  data: function data() {
    return {
      codeUrl: '',
      cookiePassword: '',
      refreshParticles: true,
      loginForm: {
        username: 'admin',
        password: '123456',
        rememberMe: false,
        code: '',
        uuid: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          message: '用户名不能为空'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: '密码不能为空'
        }],
        code: [{
          required: true,
          trigger: 'change',
          message: '验证码不能为空'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      currentTime: null,
      sysInfo: ''
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.getCode();
    // window.addEventListener('storage', this.afterQRScan)
    this.getCurrentTime();
    this.getSystemSetting();
  },
  mounted: function mounted() {
    var _this = this;
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
    window.addEventListener('resize', function () {
      _this.refreshParticles = false;
      _this.$nextTick(function () {
        return _this.refreshParticles = true;
      });
    });
  },
  destroyed: function destroyed() {
    clearInterval(this.timer);
    window.removeEventListener('resize', function () {});
    // window.removeEventListener('storage', this.afterQRScan)
  },

  methods: {
    getSystemSetting: function getSystemSetting() {
      var _this2 = this;
      this.$store.dispatch('system/settingDetail').then(function (ret) {
        _this2.sysInfo = ret;
        document.title = ret.sys_app_name;
      });
    },
    getCurrentTime: function getCurrentTime() {
      var _this3 = this;
      this.timer = setInterval(function (_) {
        _this3.currentTime = moment().format('YYYY-MM-DD HH时mm分ss秒');
      }, 1000);
    },
    getCode: function getCode() {
      var _this4 = this;
      getCodeImg().then(function (res) {
        if (res !== undefined) {
          _this4.codeUrl = res.data;
          _this4.loginForm.uuid = res.id;
        }
      });
    },
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        shiftKey = _ref.shiftKey,
        key = _ref.key;
      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    showPwd: function showPwd() {
      var _this5 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this5.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this6 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this6.loading = true;
          _this6.$store.dispatch('user/login', _this6.loginForm).then(function () {
            _this6.$router.push({
              path: _this6.redirect || '/',
              query: _this6.otherQuery
            }).catch(function () {});
          }).catch(function () {
            _this6.loading = false;
            _this6.getCode();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    }
  }
};