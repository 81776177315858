import request from '@/utils/request';

/**请求参数
ServerName     string `form:"serverName"`
ServerNumber   int    `form:"serverNumber"`
ServerState    int    `form:"serverState"`  //默认为-1 0正常 1维护 2开服准备 3等待开服
*/
//获取列表
export function listGmRegions(query) {
  return request({
    url: '/api/v1/game/gm-regions',
    method: 'get',
    params: query
  });
}

/**请求参数
	ServerName   string `json:"serverName" comment:"区服名称"`
	ServerNumber int    `json:"serverNumber" comment:"区服编号"`
	ServerConf   string `json:"serverConf" comment:"服务器配置"` //'ip地址'
	OpenTime     string `json:"openTime" comment:"开服时间"`
*/

//新增
export function addGmRegions(data) {
  return request({
    url: '/api/v1/game/gm-regions',
    method: 'post',
    data: data
  });
}

/**请求参数
	Id         int    `json:"id" comment:"区服编号"`
	ServerName string `json:"serverName" comment:"区服名称"`
	OpenTime   string `json:"openTime" comment:"开服时间"`  //没修改不需要填写
	*/

// 修改
export function updateGmRegions(data) {
  return request({
    url: '/api/v1/game/gm-regions/' + data.id,
    method: 'put',
    data: data
  });
}

// 全部区区服列表
export function gmRegionsAll(query) {
  return request({
    url: '/api/v1/game/gm-regions-all',
    method: 'get',
    params: query
  });
}