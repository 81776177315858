import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Cell from './expand.js';
export default {
  components: {
    Cell: Cell
  },
  props: {
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    showPagination: {
      // 是否显示分页组件
      type: Boolean,
      default: true
    },
    height: {
      type: String || Number,
      default: null
    }
  },
  methods: {
    handleSelectionChange: function handleSelectionChange(rows) {
      this.$emit('select', rows);
    },
    handleSizeChange: function handleSizeChange(s) {
      this.$emit('sizeChange', s);
    },
    handleCurrentChange: function handleCurrentChange(s) {
      this.$emit('pageChange', s);
    }
  }
};