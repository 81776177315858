var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 12 } },
        [
          _c(
            "el-col",
            {
              style: { marginBottom: "12px" },
              attrs: { sm: 24, xs: 24, md: 6, xl: 6, lg: 6 },
            },
            [
              _c(
                "chart-card",
                { attrs: { title: "总销售额", total: "￥126,560" } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        slot: "action",
                        effect: "dark",
                        content: "指标说明",
                        placement: "top-start",
                      },
                      slot: "action",
                    },
                    [_c("i", { staticClass: "el-icon-warning-outline" })]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "trend",
                        {
                          staticStyle: { "margin-right": "16px" },
                          attrs: { flag: "top", rate: "12" },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "term" }, slot: "term" },
                            [_vm._v("周同比")]
                          ),
                        ]
                      ),
                      _c("trend", { attrs: { flag: "bottom", rate: "11" } }, [
                        _c("span", { attrs: { slot: "term" }, slot: "term" }, [
                          _vm._v("日同比"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _vm._v("日均销售额"),
                    _c("span", [_vm._v("￥ 234.56")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-col",
            {
              style: { marginBottom: "12px" },
              attrs: { sm: 24, xs: 24, md: 6, xl: 6, lg: 6 },
            },
            [
              _c(
                "chart-card",
                { attrs: { title: "访问量", total: 8846 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        slot: "action",
                        effect: "dark",
                        content: "指标说明",
                        placement: "top-start",
                      },
                      slot: "action",
                    },
                    [_c("i", { staticClass: "el-icon-warning-outline" })]
                  ),
                  _c("div", [_c("mini-area")], 1),
                  _c("template", { slot: "footer" }, [
                    _vm._v("日访问量"),
                    _c("span", [_vm._v(" " + _vm._s("1234"))]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-col",
            {
              style: { marginBottom: "12px" },
              attrs: { sm: 24, xs: 24, md: 6, xl: 6, lg: 6 },
            },
            [
              _c(
                "chart-card",
                { attrs: { title: "支付笔数", total: 6560 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        slot: "action",
                        effect: "dark",
                        content: "指标说明",
                        placement: "top-start",
                      },
                      slot: "action",
                    },
                    [_c("i", { staticClass: "el-icon-warning-outline" })]
                  ),
                  _c("div", [_c("mini-bar")], 1),
                  _c("template", { slot: "footer" }, [
                    _vm._v("转化率 "),
                    _c("span", [_vm._v("60%")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-col",
            {
              style: { marginBottom: "12px" },
              attrs: { sm: 24, xs: 24, md: 6, xl: 6, lg: 6 },
            },
            [
              _c(
                "chart-card",
                { attrs: { title: "运营活动效果", total: "78%" } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        slot: "action",
                        effect: "dark",
                        content: "指标说明",
                        placement: "top-start",
                      },
                      slot: "action",
                    },
                    [_c("i", { staticClass: "el-icon-warning-outline" })]
                  ),
                  _c(
                    "div",
                    [
                      _c("mini-progress", {
                        attrs: {
                          color: "rgb(19, 194, 194)",
                          target: 80,
                          percentage: 78,
                          height: "8px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "footer" },
                    [
                      _c(
                        "trend",
                        {
                          staticStyle: { "margin-right": "16px" },
                          attrs: { flag: "top", rate: "12" },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "term" }, slot: "term" },
                            [_vm._v("同周比")]
                          ),
                        ]
                      ),
                      _c("trend", { attrs: { flag: "bottom", rate: "80" } }, [
                        _c("span", { attrs: { slot: "term" }, slot: "term" }, [
                          _vm._v("日环比"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { attrs: { bordered: false, "body-style": { padding: "0" } } },
        [
          _c(
            "div",
            { staticClass: "salesCard" },
            [
              _c(
                "el-tabs",
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "销售额" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c("bar", {
                                attrs: {
                                  list: _vm.barData,
                                  title: "销售额排行",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c("rank-list", {
                                attrs: {
                                  title: "门店销售排行榜",
                                  list: _vm.rankList,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "访问量" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c("bar", {
                                attrs: {
                                  list: _vm.barData2,
                                  title: "销售额趋势",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c("rank-list", {
                                attrs: {
                                  title: "门店销售排行榜",
                                  list: _vm.rankList,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }