//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ThousandsFormat } from '@/utils/num-format';
export default {
  name: 'RatioCard',
  props: {
    cardTitle: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    cardData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    cardNum: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    numFormat: function numFormat(val) {
      return ThousandsFormat(val);
    }
  }
};