import _objectSpread from "G:/renzhe/admin_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import adminDashboard from './admin';
import editorDashboard from './editor';
export default {
  name: 'Dashboard',
  components: {
    adminDashboard: adminDashboard,
    editorDashboard: editorDashboard
  },
  data: function data() {
    return {
      currentRole: 'adminDashboard'
    };
  },
  computed: _objectSpread({}, mapGetters(['roles'])),
  created: function created() {
    // if (!this.roles.includes('admin')) {
    //   this.currentRole = 'editorDashboard'
    // }
  }
};