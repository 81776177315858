//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ActionList',
  props: {
    btns: {
      type: Array,
      default: function _default() {
        return ['add', 'update', 'delete', 'export'];
      }
    },
    addPermisaction: {
      type: Array,
      default: function _default() {
        return ['admin'];
      }
    },
    updatePermisaction: {
      type: Array,
      default: function _default() {
        return ['admin'];
      }
    },
    deletePermisaction: {
      type: Array,
      default: function _default() {
        return ['admin'];
      }
    },
    exportPermisaction: {
      type: Array,
      default: function _default() {
        return ['admin'];
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    handleAdd: function handleAdd() {
      this.$emit('add');
    },
    handleUpdate: function handleUpdate() {
      this.$emit('update');
    },
    handleDelete: function handleDelete() {
      this.$emit('delete');
    },
    handleExport: function handleExport() {
      this.$emit('export');
    }
  }
};