import _objectSpread from "G:/renzhe/admin_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import PanThumb from '@/components/PanThumb';
import GithubCorner from '@/components/GithubCorner';
export default {
  name: 'DashboardEditor',
  components: {
    PanThumb: PanThumb,
    GithubCorner: GithubCorner
  },
  data: function data() {
    return {
      emptyGif: 'https://wpimg.wallstcn.com/0e03b7da-db9e-4819-ba10-9016ddfdaed3'
    };
  },
  computed: _objectSpread({}, mapGetters(['name', 'avatar', 'roles']))
};