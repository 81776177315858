var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "ratio-card" },
    [
      _vm.cardTitle.tips
        ? _c(
            "div",
            { staticClass: "card-tips" },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: _vm.cardTitle.tips,
                    placement: "bottom-end",
                  },
                },
                [_c("i", { staticClass: "el-icon-warning-outline" })]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "card-title" }, [
        _vm._v(_vm._s(_vm.cardTitle.title)),
      ]),
      _c("div", { staticClass: "card-title-sub" }, [
        _vm._v(_vm._s(_vm.cardTitle.subTitle)),
      ]),
      _c("div", { staticClass: "card-num" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.cardNum || _vm.numFormat(_vm.cardData.num)) +
              _vm._s(_vm.cardTitle.unit || "")
          ),
        ]),
        _vm.cardData.rate || _vm.cardData.rate === 0
          ? _c("span", [_vm._v(_vm._s(_vm.cardData.rate) + "%")])
          : _vm._e(),
      ]),
      _vm.$slots.ratioUl
        ? [_vm._t("ratioUl")]
        : _c("div", { staticClass: "ratio-ul" }, [
            _c("div", { staticClass: "ratio-li" }, [
              _c("div", { staticClass: "ratio-li-label" }, [_vm._v("日环比")]),
              _c(
                "div",
                {
                  staticClass: "ratio-li-value",
                  class: {
                    green: _vm.cardData.lastContrast < 0,
                    red: _vm.cardData.lastContrast > 0,
                  },
                },
                [
                  _vm.cardData.lastContrast > 0
                    ? _c("i", { staticClass: "el-icon-caret-top red" })
                    : _vm._e(),
                  _vm.cardData.lastContrast < 0
                    ? _c("i", { staticClass: "el-icon-caret-bottom green" })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.cardData.lastContrast) + "% "),
                ]
              ),
            ]),
            _c("div", { staticClass: "ratio-li" }, [
              _c("div", { staticClass: "ratio-li-label" }, [_vm._v("周同比")]),
              _c(
                "div",
                {
                  staticClass: "ratio-li-value",
                  class: {
                    green: _vm.cardData.weekContrast < 0,
                    red: _vm.cardData.weekContrast > 0,
                  },
                },
                [
                  _vm.cardData.weekContrast > 0
                    ? _c("i", { staticClass: "el-icon-caret-top red" })
                    : _vm._e(),
                  _vm.cardData.weekContrast < 0
                    ? _c("i", { staticClass: "el-icon-caret-bottom green" })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.cardData.weekContrast) + "% "),
                ]
              ),
            ]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }