import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
var _data = [];
var beginDay = new Date().getTime();
for (var i = 0; i < 10; i++) {
  _data.push({
    x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
    y: Math.round(Math.random() * 10)
  });
}
var tooltip = ['x*y', function (x, y) {
  return {
    name: x,
    value: y
  };
}];
var scale = [{
  dataKey: 'x',
  min: 2
}, {
  dataKey: 'y',
  title: '时间',
  min: 1,
  max: 22
}];
export default {
  name: 'MiniArea',
  data: function data() {
    return {
      data: _data,
      tooltip: tooltip,
      scale: scale,
      height: 100
    };
  }
};