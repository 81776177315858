import _objectSpread from "G:/renzhe/admin_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { tableToExcel } from '@/utils/index';
import { dateFormat } from '@/filters';
import CompareForm from './CompareForm';
export default {
  name: 'AnalyseChartCard',
  components: {
    CompareForm: CompareForm
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    shadow: {
      type: String,
      default: 'always'
    },
    showDate: {
      type: Boolean,
      default: true
    },
    showChannel: {
      type: Boolean,
      default: true
    },
    compare: {
      type: Boolean,
      default: false
    },
    contrastPermisaction: {
      type: Array,
      default: function _default() {
        return ['admin'];
      }
    },
    exportPermisaction: {
      type: Array,
      default: function _default() {
        return ['admin'];
      }
    }
  },
  data: function data() {
    var date = this.currentWeek();
    return {
      chartForm: {
        serverId: '',
        date: date,
        channel: ''
      }
    };
  },
  computed: {
    serverData: function serverData() {
      // 服务器列表
      var gameRegions = this.$store.getters.gameRegions;
      var serverOptions = [{
        label: '全服',
        value: ''
      }];
      gameRegions.forEach(function (g) {
        serverOptions.push({
          label: g.name,
          value: g.id
        });
      });
      return serverOptions;
    },
    channelOptions: function channelOptions() {
      var channel = this.$store.getters.channelData;
      var channelOptions = [{
        label: '全渠道',
        value: ''
      }];
      channel.forEach(function (c) {
        channelOptions.push({
          label: c.channelName,
          value: c.channelName
        });
      });
      return channelOptions;
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.itemChange();
    });
  },
  methods: {
    clearCompare: function clearCompare() {
      this.$emit('clearCompare');
    },
    doCompare: function doCompare(e) {
      this.$emit('doCompare', e);
    },
    lineCompare: function lineCompare() {
      this.$refs.CompareForm.show();
    },
    currentWeek: function currentWeek() {
      // 最近一周时间
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [dateFormat(start, 'yyyy-MM-dd 00:00:00'), dateFormat(end, 'yyyy-MM-dd 00:00:00')];
    },
    itemChange: function itemChange(e) {
      // 格式化时间范围
      if (this.showDate) {
        if (this.chartForm.date && this.chartForm.date.length > 0) {
          this.chartForm.beginTime = this.chartForm.date[0];
          this.chartForm.endTime = this.chartForm.date[1].replace('00:00:00', '23:59:59');
        } else {
          this.$message.error('请选择时间范围');
          return;
        }
      }
      this.$emit('change', this.chartForm);
    },
    setOption: function setOption(option) {
      // 初始化默认option
      var chartDom = this.$refs.chartElement;
      var myChart = this.$echarts.init(chartDom);
      var baseOption = _objectSpread({
        tooltip: {
          trigger: 'axis'
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: []
      }, option);
      option && myChart.setOption(baseOption, true);
    },
    handleExport: function handleExport() {
      this.$emit('change', _objectSpread(_objectSpread({}, this.chartForm), {}, {
        excel: function excel(body, header, exportName) {
          tableToExcel(body, header, exportName);
        }
      }));
    }
  }
};